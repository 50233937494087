import React from 'react'


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import { LuClipboardSignature, LuNewspaper } from 'react-icons/lu';
import { MdOutlineFolderCopy } from 'react-icons/md';
import { GrDocumentStore } from 'react-icons/gr';
import { RiContactsLine } from 'react-icons/ri';
import { FaRegImages } from 'react-icons/fa';

const Services = () => {
    return (
        <>


            <section className="py-10 lg:py-20 px-5 md:px-10 lg:14">
                <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
                    <div className="max-w-xl mx-auto text-center mb-14">
                        <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-200 text-gray-950">Business Solutions</span>
                        <h2 className="text-4xl/tight font-medium text-gray-950 mt-4">Our Offered Services</h2>
                        <p className="text-base font-medium mt-5">Adaptable layouts and immediate outcomes! Select a pre-designed header or craft a personalized layout that precisely aligns with your requirements.</p>
                    </div>

                    <div className="relative gap-8 m-auto flex overflow-hidden">
                        
                         
                                <div className="marquee__group gap-8 py-2 flex items-center justify-around flex-shrink-0 min-w-full">
                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <LuNewspaper className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Search Engine Optimization</h4>
                                        </div>
                                    

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <MdOutlineFolderCopy className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Social Media Marketing</h4>
                                        </div>
                                    

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <LuClipboardSignature className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">E-commerce Solutions</h4>
                                        </div>
                                   

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <GrDocumentStore className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Pay-Per-Click Advertising</h4>
                                        </div>
                                   

                                    
                                        <div className="box-border p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <RiContactsLine className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Branding Strategy</h4>
                                        </div>
                                    

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <FaRegImages className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Marketing Copywriting</h4>
                                        </div>
                                    
                                </div>
                            

                           
                                <div className="marquee__group gap-8 py-2 flex items-center justify-around flex-shrink-0 min-w-full">
                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <LuNewspaper className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Search Engine Optimization</h4>
                                        </div>
                                    

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <MdOutlineFolderCopy className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Social Media Marketing</h4>
                                        </div>
                                   

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <LuClipboardSignature className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">E-commerce Solutions</h4>
                                        </div>
                                    

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <GrDocumentStore className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Pay-Per-Click Advertising</h4>
                                        </div>
                                    

                                    
                                        <div className="box-border p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <RiContactsLine className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Branding Strategy</h4>
                                        </div>
                                    

                                    
                                        <div className="p-6 rounded-xl bg-white shadow-lg text-center transition-all duration-200 dark:bg-default-50 border-2 border-white hover:!border-[#0e104b]">
                                            <div className="flex justify-center">
                                                <FaRegImages className="h-10 w-10 text-[#0e104b]" />
                                            </div>
                                            <h4 className="text-lg font-medium text-gray-950 mt-5">Marketing Copywriting</h4>
                                        </div>
                                    
                                </div>
                            
                    </div>

                </div>
            </section>
        </>
    )
}

export default Services