import React, { useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { BiSolidPhoneCall } from 'react-icons/bi'
import { SiMinutemailer } from 'react-icons/si'
import { FaXTwitter } from 'react-icons/fa6'
import { MdLocationPin } from 'react-icons/md'
import BackToTop from './BackToTop'
import { Link } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa'



const Contactus = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};

    if (!formData.firstName.trim()) {
      formErrors.firstName = 'First Name is required';
    }

    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email is invalid';
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d+$/.test(formData.phoneNumber)) {
      formErrors.phoneNumber = 'Phone Number must be numeric';
    }

    // if (!formData.lastName.trim()) {
    //   formErrors.lastName = 'Last Name is required';
    // }

    // if (!formData.message.trim()) {
    //   formErrors.message = 'Message is required';
    // }

    return formErrors;
  };


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      // Form is valid, submit the form

      // Construct the WhatsApp message
      const whatsappMessage = `FirstName: ${formData.firstName}\nLastName: ${formData.lastName}\nEmail: ${formData.email}\nMessage: ${formData.message}`;

      // WhatsApp URL with the encoded message
      const whatsappURL = `https://api.whatsapp.com/send?phone=7871928227&text=${encodeURIComponent(whatsappMessage)}`;

      // Open WhatsApp with the pre-filled message
      window.open(whatsappURL, '_blank');

      // Optionally, clear the form after submission
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <section>
      <Navbar />

      <div className="bg-no-repeat bg-cover bg-[url('assets/other/bg-lines-1.svg')]">
        <div className="py-10 px-5 md:px-10 lg:px-14">
          <div className="container mx-auto">
            <div data-aos="zoom-in" data-aos-duration="1000" className="rounded-lg bg-gradient-to-l from-[#989de6]/40 to-[#989de6]/30 via-[#7f83b9]/0 py-20 px-6  ">
              <div className="max-w-2xl mx-auto text-center">
                <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-400/50 text-gray-950">Contact Us</span>
                <h2 className="md:text-4xl text-3xl font-bold text-gray-950 mt-5">We've got an entire team dedicated to supporting you and your business</h2>
                <p className="text-base font-medium text-gray-500 mt-6">Get help 24/7, with support our team experts</p>

              </div>
            </div>
          </div>
        </div>



        <div className='lg:py-20 py-10 px-5 md:px-10 lg:px-14'>
          <div data-aos="zoom-in-up" data-aos-duration="1000" className='container mx-auto space-y-6 md:space-y-0 md:flex gap-10'>

            <div className='w-full md:w-1/2 px-3 space-y-6 md:space-y-10 pt-3'>


              <div className='space-y-1'>
                <p className='text-lg lg:text-xl font-bold text-[#0e104b]'>Call Us</p>
                <p className='text-sm md:text-base text-gray-400'>Call our team Mon-Fri from 9am to 8pm.</p>
                <p className='flex items-center gap-2 lg:text-lg font-medium text-[#0e104b] pt-2.5'>
                  <span><BiSolidPhoneCall /></span>
                  <Link to={'tel:9944362287'} className="hover:text-[#ffcc4d]" >+91 9944362287</Link>
                </p>
                <p className='flex items-center gap-2 lg:text-lg font-medium text-[#0e104b] '>
                  <span><FaWhatsapp /></span>
                  <Link to={'https://wa.me/8939362287'} className="hover:text-[#ffcc4d]" target='_blank'>+91 8939362287</Link>
                </p>
              </div>

              <div className='space-y-1'>
                <p className='text-lg lg:text-xl font-bold text-[#0e104b]'>Chat with us</p>
                <p className='text-sm md:text-base text-gray-400'>Speak to our friendly chat</p>
                <p className='flex items-center gap-2 lg:text-lg font-medium text-[#0e104b] pt-2.5'>
                  <span><SiMinutemailer /></span>
                  <Link to={'mailto:admin@arnet.in'} className="hover:text-[#ffcc4d]">Shoot us an email </Link>
                </p>
                <p className='flex items-center gap-2 lg:text-lg font-medium text-[#0e104b] '>
                  <span><FaWhatsapp /></span>
                  <Link to={'https://wa.me/8939362287'} className="hover:text-[#ffcc4d]" target='_blank'>Ping us on WhatsApp</Link>
                </p>
                <p className='flex items-center gap-2 lg:text-lg font-medium text-[#0e104b] '>
                  <span><FaXTwitter /></span>
                  <Link to={'#'} className="hover:text-[#ffcc4d]" target='_blank'>Message us on Twitter</Link>
                </p>
              </div>

              <div className='space-y-1'>
                <p className='text-lg lg:text-xl font-bold text-[#0e104b]'>Visit Us</p>
                <p className='text-sm md:text-base text-gray-400'>Chat to us in person at our Office</p>
                <p className='flex items-center gap-2 lg:text-lg font-medium text-[#0e104b] pt-2.5'>
                  <span><MdLocationPin /></span>
                  <span>44/1,3rd street, sangeeth nagar,
                    Madurai-625018, Tamilnadu, India.</span>
                </p>
              </div>

            </div>


            <div className='w-full md:w-1/2 px-3 bg-white/50 p-3'>
              <form action="#" className='space-y-3 md:space-y-5' onSubmit={handleSubmit}>


                <div className='space-y-3 md:space-y-0 md:flex gap-5 '>
                  <div className='w-full space-y-1'>
                    <label htmlFor="" className='font-medium text-[#0e104b]'>First name</label>
                    <input className={`${errors.firstName ? "ring-red-500 focus:ring-red-500" : "focus:ring-[#0e104b] hover:ring-[#191c6b]"}  bg-transparent outline-none w-full ring-2 ring-gray-300   rounded-md px-3 py-1.5`} placeholder='First name'
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}

                    />
                    {errors.firstName && <p className='text-xs text-red-500'>{errors.firstName}</p>}
                  </div>
                  <div className='w-full space-y-1'>
                    <label htmlFor="" className='font-medium text-[#0e104b]'>Last name</label>
                    <input className={' bg-transparent outline-none w-full ring-2 ring-gray-300 focus:ring-[#0e104b] hover:ring-[#191c6b] rounded-md px-3 py-1.5'} placeholder='Last name'

                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}


                    />
                  </div>
                </div>

                <div className='w-full space-y-1'>
                  <label htmlFor="" className='font-medium text-[#0e104b]'>Email</label>
                  <input className={`${errors.email ? "ring-red-500 focus:ring-red-500" : "focus:ring-[#0e104b] hover:ring-[#191c6b]"}  bg-transparent outline-none w-full ring-2 ring-gray-300   rounded-md px-3 py-1.5`} placeholder='you@company.com'
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}

                  />
                  {errors.email && <p className='text-xs text-red-500'>{errors.email}</p>}
                </div>

                <div className='w-full space-y-1'>
                  <label htmlFor="" className='font-medium text-[#0e104b]'>Phone number</label>
                  <input className={`${errors.phoneNumber ? "ring-red-500 focus:ring-red-500" : "focus:ring-[#0e104b] hover:ring-[#191c6b]"}  bg-transparent outline-none w-full ring-2 ring-gray-300   rounded-md px-3 py-1.5`} placeholder='+91 52524 67678'
                    type="number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}

                  />
                  {errors.phoneNumber && <p className='text-xs text-red-500'>{errors.phoneNumber}</p>}
                </div>

                <div className='w-full space-y-1'>
                  <label htmlFor="" className='font-medium text-[#0e104b]'>Message</label>
                  <textarea rows={6} className='w-full outline-none ring-2 ring-gray-300 rounded-md px-3 py-2 focus:ring-[#0e104b] hover:ring-[#191c6b] ' placeholder='Leave as a message...'
                    name="message"
                    value={formData.message}
                    onChange={handleChange}

                  />
                </div>

                <button className='bg-[#0e104b] hover:bg-[#191c6b] rounded-full w-full mt-5 md:mt-10 py-2.5 text-neutral-50 font-medium'>Send Message</button>
              </form>
            </div>
          </div>
        </div>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125766.1308695958!2d78.04042069567895!3d9.917995903671342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c582b1189633%3A0xdc955b7264f63933!2sMadurai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1724695822348!5m2!1sen!2sin" className='border-none lg:h-[450px] w-full' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

        <Footer />

        <BackToTop />
      </div>

    </section>
  )
}

export default Contactus