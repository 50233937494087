import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import HeroSection from './HeroSection'
import FeatureSection from './FeatureSection'
import ServicesSection from './ServicesSection'
import BlogSection from './BlogSection'
import BackToTop from '../BackToTop'

const Webdevelopment = () => {
  return (
    <div className='bg-gray-100/70'>
      <Navbar />
      
      <HeroSection />

      <FeatureSection />

      <ServicesSection />

      <BlogSection />

      <Footer />

      <BackToTop />
    </div>
  )
}

export default Webdevelopment