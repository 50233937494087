import React from 'react'
import img1 from '../../assets/landing/marketing/img-4.jpg';
import { LuCodesandbox, LuLayers, LuMoveRight } from 'react-icons/lu';
import { PiMonitorBold } from 'react-icons/pi';

const Services = () => {
    return (
        <>
            <section className="py-10 px-3 md:px-10">
                <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
                    <div className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-200 text-default-950">Services</span>
                            <h2 className="text-3xl font-medium capitalize text-default-950 my-4">We want to bring business & the digital world together.</h2>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-2 gap-6 items-center">
                        <div>
                            <div className="grid  grid-cols-1 items-center rounded-md overflow-hidden border border-default-200">
                                <div className="group">
                                    <div className="flex flex-wrap items-center gap-6 p-6">
                                        <div>
                                            <LuLayers className="h-14 w-14 text-default-950" />
                                        </div>
                                        <div>
                                            <h2 className="text-2xl text-default-950 font-medium mb-4">Product Design</h2>
                                            <p className="text-base mb-6">Innovative Product Design for Unique User Experiences</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="group border-y border-default-200">
                                    <div className="flex flex-wrap items-center gap-6 p-6">
                                        <div>
                                            <PiMonitorBold className="h-14 w-14 text-default-950" />
                                        </div>
                                        <div>
                                            <h2 className="text-2xl text-default-950 font-medium mb-4">Marketing Strategy</h2>
                                            <p className="text-base mb-6">Strategic Marketing Solutions for Business Growth</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="group">
                                    <div className="flex flex-wrap items-center gap-6 p-6">
                                        <div>
                                            <LuCodesandbox className="h-14 w-14 text-default-950" />
                                        </div>
                                        <div>
                                            <h2 className="text-2xl text-default-950 font-medium mb-4">E-Commerce</h2>
                                            <p className="text-base mb-6">Comprehensive E-Commerce Development and Optimization</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <img src={img1} className="mx-auto" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services