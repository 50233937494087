import React from 'react'
import img1 from '../../assets/option/computer-man.jpg';

const ContentSection = () => {
  return (
    <>
        <section className="py-10 px-5 md:px-10 lg:px-14 overflow-hidden">
        <div data-aos="fade-up" data-aos-duration="1000" className="container mx-auto">
            <div className="bg-white   overflow-hidden relative dark:bg-default-50">
                <div className="grid lg:grid-cols-2 gap-6 items-center">
                    <img src={img1} className="h-full w-full rounded-lg" alt="" />

                    <div className="relative p-6">
                        <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-200 text-gray-950">Exploratory Studies</span>
                        <h2 className="text-3xl font-medium text-gray-950 mt-4">Ideas Driven byPassion. <span className='animate-pulse transition duration-700'>✏️</span></h2>
                        <p className="text-base font-medium mt-5 mb-8">We strongly hold that partnering with our clients to develop products and services is the most efficient method to achieve our objectives.</p>

                        <div>
                            <div className="flex items-center justify-between gap-6">
                                <h2 className="text-lg font-medium text-gray-950">Search Engine</h2>
                                <h4 className="text-lg font-medium text-gray-950">75</h4>
                            </div>
                            <div className="flex w-full h-4 bg-gray-200 rounded-full overflow-hidden mt-1">
                                <div className="flex flex-col justify-center overflow-hidden bg-[#0e104b] w-[75%]" role="progressbar"  aria-valuenow="46" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="my-10">
                            <div className="flex items-center justify-between gap-6">
                                <h2 className="text-lg font-medium text-gray-950">Digital marketing</h2>
                                <h4 className="text-lg font-medium text-gray-950">95</h4>
                            </div>
                            <div className="flex w-full h-4 bg-gray-200 rounded-full overflow-hidden mt-1">
                                <div className="flex flex-col justify-center overflow-hidden bg-[#0e104b] w-[95%]" role="progressbar"  aria-valuenow="46" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between gap-6">
                                <h2 className="text-lg font-medium text-gray-950">App Development</h2>
                                <h4 className="text-lg font-medium text-gray-950">85</h4>
                            </div>
                            <div className="flex w-full h-4 bg-gray-200 rounded-full overflow-hidden mt-1">
                                <div className="flex flex-col justify-center overflow-hidden bg-[#0e104b] w-[85%]" role="progressbar"  aria-valuenow="46" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ContentSection