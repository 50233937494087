import React from 'react'
import { GoCodeOfConduct } from 'react-icons/go'
import { MdOutlineDesignServices } from 'react-icons/md'
import { PiDiamondsFourBold } from 'react-icons/pi'
import { TbBrandBaidu } from 'react-icons/tb'

const WhoWeAre = () => {
  return (
    <section className="py-10 lg:py-20 px-5 md:px-10 lg:px-14">
        <div data-aos="zoom-in-left" data-aos-duration="1000" className="container mx-auto">
            <div className="grid lg:grid-cols-2 gap-6 items-center">
                
                <div className='space-y-5'>
                <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-300 text-gray-950">Our Services</span>
                    <h2 className="text-4xl font-medium text-default-950">Who We Are ⚡</h2>
                    <p className="text-base font-medium mt-5 mb-10">We are a collective of branding experts, designers, strategists, and storytellers. Our team combines creativity with strategic insight to deliver branding solutions that not only look great but also achieve your business objectives. With a keen eye for detail and a commitment to excellence, we bring your brand vision to life.</p>
                    
                </div>

                <div>
                    <div className="flex flex-wrap items-center justify-center gap-6">
                        <div className="p-6 rounded-xl text-center bg-purple-500/10 w-52 h-40">
                            <div className="flex justify-center">
                                <MdOutlineDesignServices className="h-12 w-12 text-purple-600" />
                            </div>
                            <h3 className="text-xl font-medium text-purple-600 mt-3">Designs</h3>
                        </div>

                        <div className="p-6 rounded-xl text-center bg-green-500/10 w-52 h-40 md:-mt-14">
                            <div className="flex justify-center">
                                <TbBrandBaidu  className="h-12 w-12 text-green-600" />
                            </div>
                            <h3 className="text-xl font-medium text-green-600 mt-3">Branding</h3>
                        </div>
                    </div>

                    <div className="flex flex-wrap items-center justify-center gap-5 mt-6">
                        <div className="p-6 rounded-xl text-center bg-red-500/10 w-52 h-40">
                            <div className="flex justify-center">
                                <GoCodeOfConduct className="h-12 w-12 text-red-600" />
                            </div>
                            <h3 className="text-xl font-medium text-red-600 mt-3">Consultation</h3>
                        </div>
                        <div className="p-6 rounded-xl text-center bg-sky-500/10 w-52 h-40 md:-mt-14">
                            <div className="flex justify-center">
                                <PiDiamondsFourBold className="h-12 w-12 text-sky-600" />
                            </div>
                            <h3 className="text-xl font-medium text-sky-600 mt-3">Marketing</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhoWeAre