import React from 'react'
import img1 from '../../assets/landing/creative/img-6.png';
import img2 from '../../assets/landing/creative/img-8.png';
import { LuMoveRight } from 'react-icons/lu';

const BlogSection = () => {
    return (
        <>
            <section className="py-10 lg:py-20 px-3 md:px-10 lg:px-14">
                <div data-aos="fade-up" data-aos-duration="1000" className="container mx-auto">
                    <div className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-300 text-gray-950">Our Blog</span>
                            <h2 className="text-3xl font-medium capitalize text-gray-950 my-4">Read the latest news.</h2>
                        </div>
                    </div>

                    <div className="grid xl:grid-cols-2 gap-6">
                        <div className="p-6 border border-gray-200 rounded-md">
                            <div className="grid sm:grid-cols-5 gap-6 items-center">
                                <div className="col-span-2">
                                    <img src={img1} className="rounded-md my-auto h-full w-full" alt="" />
                                </div>

                                <div className="sm:col-span-3 group">
                                    <h3 className="text-3xl font-medium text-gray-950 mb-4 mt-3">Free advertising you online business.</h3>
                                    <p className="text-base font-medium text-gray-800 mb-5 mt-3">Content king that means bringing and strategic approach.</p>
                                    <a className="text-gray-950 group-hover:text-[#0e104b] text-lg font-medium transition-all duration-500">Read More
                                        <LuMoveRight className="group-hover:opacity-100 opacity-0 w-4 h-4 text-[#0e104b] inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 border border-gray-200 rounded-md">
                            <div className="grid sm:grid-cols-5 gap-6 items-center">
                                <div className="sm:col-span-3 group">
                                    <h3 className="text-3xl font-medium text-gray-950 mb-4 mt-3">Investment monitor top 2023</h3>
                                    <p className="text-base font-medium text-gray-800 mb-5 mt-3">Content king that means bringing and strategic approach.</p>
                                    <a className="text-gray-950 group-hover:text-[#0e104b] text-lg font-medium group transition-all duration-500">Read More
                                        <LuMoveRight className="group-hover:opacity-100 opacity-0 w-4 h-4 text-[#0e104b] inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform" />
                                    </a>
                                </div>

                                <div className="col-span-2">
                                    <img src={img2} className="rounded-md my-auto h-full w-full" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogSection