import React from 'react'
import Navbar from '../Navbar'
import HeroSection from './HeroSection'
import Footer from '../Footer'
import AboutSection from './AboutSection'
import Services from './Services'
import ContentSection from './ContentSection'
import BackToTop from '../BackToTop'
import CTASection from './CTASection'

const DigitalMarketing = () => {
  return (
    <section>
      <Navbar />

      <HeroSection />

      <Services />

      <AboutSection />

      <ContentSection />

      <CTASection />

      <Footer />

      <BackToTop />

    </section>
  )
}

export default DigitalMarketing