import React from 'react'

import img1 from '../../assets/landing/marketing-3/img-5.jpg';
import img2 from '../../assets/landing/marketing-3/img-6.jpg';

const OurValues = () => {
  return (
    <section className="py-10 lg:py-20 px-5 md:px-10 lg:px-14">
        <div data-aos="fade-up" data-aos-duration="1000" className="container mx-auto">
            <div className="grid lg:grid-cols-2 gap-6 items-end">
                <div>
                    <h2 className="text-4xl font-medium text-gray-950 mb-8">Our Values</h2>

                    <div className="rounded-xl border bg-gray-50/70 border-gray-200">
                        <div className="grid md:grid-cols-2">
                            <div className="p-6">
                                <h2 className="text-xl font-medium text-gray-950">Excellence</h2>
                                <p className="text-sm font-medium mt-3">We are committed to delivering exceptional quality and exceeding expectations.</p>
                            </div>

                            <div className="p-6 border-s border-gray-200">
                                <h2 className="text-xl font-medium text-gray-950">Integrity</h2>
                                <p className="text-sm font-medium mt-3">We uphold the highest standards of honesty and transparency in all our client relationships.</p>
                            </div>

                            <div className="p-6 border-e border-t border-gray-200">
                                <h2 className="text-xl font-medium text-gray-950">Creativity</h2>
                                <p className="text-sm font-medium mt-3">We thrive on pushing creative boundaries to deliver unique and impactful branding solutions.</p>
                            </div>

                            <div className="p-6 border-t border-gray-200">
                                <h2 className="text-xl font-medium text-gray-950">Collaboration</h2>
                                <p className="text-sm font-medium mt-3">We believe in working together to achieve the best outcomes, valuing your input and expertise throughout the process.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative mb-20 lg:mb-0">
                    <div className="relative">
                        <img src={img1} className="mx-auto rounded-xl" alt="" />
                    </div>
                    <div className="absolute inset-x-0 -bottom-14 hidden sm:block">
                        <img src={img2} className="h-full rounded-xl" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default OurValues