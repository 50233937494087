import React from 'react'
import { LuMoveRight } from 'react-icons/lu'
import img1 from '../../assets/landing/agency/img-13.jpg';
import img2 from '../../assets/landing/agency/img-14.jpg';
import img3 from '../../assets/landing/agency/img-15.jpg';
import { RiMindMap, RiShapesLine } from 'react-icons/ri';
import { HiMiniRocketLaunch } from 'react-icons/hi2';
import { AiFillPieChart, AiOutlineApartment, AiOutlinePieChart } from 'react-icons/ai';
import { GoRocket } from 'react-icons/go';

const Blog = () => {
  return (
    <>
<section className="lg:pb-20 py-10 px-3 md:px-10">
        <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
            <div className="flex items-end justify-between mb-10">
                <div className="max-w-2xl mx-auto text-center">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-300 text-gray-950">Our Blog</span>
                    <h2 className="text-3xl font-medium capitalize text-gray-950 my-4">Latest from our blog</h2>
                    <p className="text-base font-medium text-default-800">In this guide, we're going to share the essential SEO ranking factors you need to dominate search. By the end of this post, you'll have a well-optimized site</p>
                </div>
            </div>

            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                <div className="rounded-lg border border-gray-200 overflow-hidden">
                    <div className="p-6 group">
                        <div className="relative rounded-md overflow-hidden">
                            <img src={img1} className="rounded-md" alt=""/>
                            <div className="absolute inset-0 bg-black/40"></div>
                        </div>

                        <div className="flex gap-5 mt-6">
                            <div>
                                <h2 className="text-3xl font-medium text-gray-900"><GoRocket /></h2>
                                
                            </div>
                            <div>
                                <h2 className="text-2xl font-medium text-gray-950">Brilliant minds.</h2>
                                <p className="text-base font-medium text-default-800 mt-3 mb-5">Our team consists of innovative thinkers and industry experts who bring fresh ideas and strategic insights to every project. We combine creativity with technical expertise to deliver solutions that drive success.</p>
                                {/* <a href="#" className="text-gray-950 text-lg font-medium">Read More
                                    <LuMoveRight className="group-hover:opacity-100 opacity-0 w-6 h-6 inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform"></LuMoveRight>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rounded-lg border border-gray-200 overflow-hidden">
                    <div className="p-6 group">
                        <div className="relative rounded-md overflow-hidden">
                            <img src={img2} className="rounded-md" alt=""/>
                            <div className="absolute inset-0 bg-black/40"></div>
                        </div>

                        <div className="flex gap-5 mt-6">
                            <div>
                                <h2 className="text-3xl font-medium text-gray-900"><AiOutlinePieChart /></h2>
                               
                            </div>
                            <div>
                                <h2 className="text-2xl font-medium text-gray-950">Digital Marketing.</h2>
                                <p className="text-base font-medium text-default-800 mt-3 mb-5">We specialize in data-driven digital marketing strategies that boost your online presence, engage your audience, and deliver measurable results. From SEO to social media, we help your brand grow in the digital landscape.</p>
                                {/* <a href="#" className="text-gray-950 text-lg font-medium">Read More
                                    <LuMoveRight className="group-hover:opacity-100 opacity-0 w-6 h-6 inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform"></LuMoveRight>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="rounded-lg border border-gray-200 overflow-hidden">
                    <div className="p-6 group">
                        <div className="relative rounded-md overflow-hidden">
                            <img src={img3} className="rounded-md" alt=""/>
                            <div className="absolute inset-0 bg-black/40"></div>
                        </div>

                        <div className="flex gap-5 mt-6">
                            <div>
                                <h2 className="text-3xl font-medium text-gray-900"><AiOutlineApartment /></h2>
                            </div>
                            <div>
                                <h2 className="text-2xl font-medium text-gray-950">Good thinking.</h2>
                                <p className="text-base font-medium text-default-800 mt-3 mb-5">At the core of our success is good thinking—creative problem-solving and strategic planning that align with your business goals. We believe in thoughtful, well-executed ideas that make a real impact.</p>
                                {/* <a href="#" className="text-gray-950 text-lg font-medium">Read More
                                    <LuMoveRight className="group-hover:opacity-100 opacity-0 w-6 h-6 inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform"></LuMoveRight>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Blog