import React from 'react';

import Navbar from '../Navbar';
import Footer from '../Footer';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ServiceSection from './ServiceSection';
import Faq from './Faq';
import Blog from './Blog';
import BackToTop from '../BackToTop';




const HomePage2 = () => {
    return (
        <section>
            
            
            <Navbar />
            
            <HeroSection />

            <AboutSection />

            <ServiceSection />

            <Faq />

            <Blog />

            <Footer />

            <BackToTop />
        </section>
    );
}

export default HomePage2;
