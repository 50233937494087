import React from 'react'
import { CiGrid42 } from 'react-icons/ci'
import { LuLayers, LuLayoutGrid, LuMoveRight } from 'react-icons/lu'
import { PiDiamondsFour } from 'react-icons/pi'

const ServiceSection = () => {
  return (
    <div>
        <section className="lg:py-20 py-10 px-3 md:px-10 lg:px-14">
        <div data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" className="container mx-auto">
            <div className="flex items-end justify-between mb-10">
                <div className="max-w-2xl mx-auto text-center">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-300 text-gray-950">Services</span>
                    <h2 className="text-4xl font-medium capitalize text-gray-950 my-4">Our Services</h2>
                    <p className="text-base text-gray-800 font-medium">Our team specializes in creating robust, scalable, and user-friendly web and mobile applications.</p>
                </div>
            </div>

            <div className="grid lg:grid-cols-3 grid-cols-1 items-center rounded-md overflow-hidden bg-gray-100 dark:bg-gray-50 divide-y lg:divide-y-0 lg:divide-x divide-gray-200">
                <div className="group">
                    <div className="sm:p-10 p-8">
                        <span>
                        <PiDiamondsFour className="h-14 w-14 text-gray-950"/>
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Developer</h2>
                        <p className="text-base text-gray-800 mb-6">Expert in Crafting High-Quality Web and Mobile Applications Tailored to Your Business Needs.</p>
                        {/* <a className="text-gray-950 text-lg font-medium">Read More
                        <LuMoveRight className="group-hover:opacity-100 opacity-0 w-6 h-6 inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform" />
                        </a> */}
                    </div>
                </div>

                <div className="group">
                    <div className="sm:p-10 p-8">
                        <span>
                        <LuLayers className="h-14 w-14 text-gray-950"/>
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Digital Product Design</h2>
                        <p className="text-base text-gray-800 mb-6">Delivering Engaging Digital Production Design That Captures Attention and Enhances User Experience.</p>
                        {/* <a className="text-gray-950 text-lg font-medium">Read More
                        <LuMoveRight className="group-hover:opacity-100 opacity-0 w-6 h-6 inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform" />
                        </a> */}
                    </div>
                </div>

                <div className="group">
                    <div className="sm:p-10 p-8">
                        <span>
                        <LuLayoutGrid className="h-14 w-14 text-gray-950"/>
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Branding & Design</h2>
                        <p className="text-base text-gray-800 mb-6">Creating Strong, Memorable Branding and Design Solutions to Elevate Your Brand's Identity and Impact.</p>
                        {/* <a className="text-gray-950 text-lg font-medium">Read More
                            <LuMoveRight className="group-hover:opacity-100 opacity-0 w-6 h-6 inline-block transition-all duration-500 group-hover:translate-x-2 will-change-transform" />
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default ServiceSection