import React from 'react'
import svg1 from '../../assets/option/slider.png';

const HeroSection = () => {
    return (
        <>
            <section className="py-10 lg:py-14 px-3 md-px-10 lg:px-20 flex items-center justify-center bg-default-100 dark:bg-default-50">
                <div className="container">
                    <div data-aos="zoom-in" data-aos-duration="1000" className="grid xl:grid-cols-2 grid-cols-1 gap-6">


                        <div className="flex h-full  items-center py-16">
                            <div className="text-center mx-auto relative">
                                <span className="py-1 px-3 rounded-md text-sm font-medium uppercase tracking-wider text-gray-950  bg-black/5">knowledge hub</span>
                                <h1 className="md:text-5xl/snug text-3xl font-medium text-gray-950 mt-3">Build Quickly, Earn More</h1>
                                <p className="w-3/4 mx-auto text-base font-medium mt-5">Leverage customer data to create exceptional and robust product experiences that drive conversions.</p>

                                {/* <div className="flex justify-center mt-10">
                                        <a href="#" className="inline-flex items-center justify-center gap-2 text-base py-2 px-8 rounded-md text-gray-950 bg-gray-300/60 hover:bg-gray-700 transition-all duration-700 hover:text-neutral-50">Read More
                                           
                                        </a>
                                    </div> */}
                            </div>
                        </div>

                        <div>
                            <img src={svg1} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroSection