import React from "react";
import footerLogo from "../assets/logo-horizontal-full-grap.png";
import Banner from "../assets/footer-pattern.jpg";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  
} from "react-icons/fa";
import { GiPositionMarker } from "react-icons/gi";
import { BiSolidPhoneCall } from "react-icons/bi";
import { HiMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

const BannerImg = {
  backgroundImage: `url(${Banner})`,
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100%",
  width: "100%",
};

const FooterLinks = [
  
    {
      title: "Business Solution",
      link: "/BusinessSolution",
    },
    {
      title: "Digital Marketing",
      link: "/DigitalMarketing",
    },
    {
      title: "Web Development",
      link: "/WebDevelopment",
    },
    
  ];

const FooterLinks2 = [
  
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About",
    link: "/About",
  },
  {
    title: "Career",
    link: "/Career",
  },
  {
    title: "Contact",
    link: "/Contactus",
  },
  
];

const Footer = () => {
  return (
    <div style={BannerImg} className="text-white">
      <div className="container  px-3">
        <div data-aos="zoom-in" className="grid md:grid-cols-3 pb-44 pt-5">
          {/* company details */}
          <div className="py-8 px-4">
            <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
              <img src={footerLogo} alt="logo" className="h-16 md:h-20 brightness-50 invert grayscale hue-rotate-120 -translate-x-5" />
              
            </h1>
            <p className="text-gray-400/80">
            Empowering brands with innovative digital marketing strategies for online growth.
            </p>

            <div className="flex items-center gap-3 mt-6 text-2xl md:text-3xl">
              <Link to={`https://wa.me/8939362287`} target="_blank">
              <FaWhatsapp />
              </Link>

              <Link to={`https://www.facebook.com/share/Ex9Kbb9CTQCfrSJ4/?mibextid=qi2Omg`} target="_blank">
              <FaFacebook />
              </Link>

              <Link to={`https://www.instagram.com/arnetworksssolutions?igsh=NmY2NTJqYjJzcG92`} target="_blank">
              <FaInstagram />
              </Link>

              <Link to={`#`} target="_blank">
              <FaLinkedin />
              </Link>

              <Link to={`#`} target="_blank">
              <FaXTwitter />
              </Link>
              
                
              </div>
          </div>

          {/* Footer Links */}
          <div className="grid grid-cols-2 sm:grid-cols-3 col-span-2 md:pl-10">

          
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3 ">
                  Links
                </h1>
                <ul className="flex flex-col gap-3">
                  {FooterLinks2.map((link) => (
                    <li
                      className="cursor-pointer hover:text-[#ffcc4d] hover:translate-x-1 duration-300 text-gray-200"
                      key={link.title}
                    >
                      <Link to={`${link.link}`}>{link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            

            
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                Our Work
                </h1>
                <ul className="flex flex-col gap-3">
                    
                  {FooterLinks.map((link) => (
                    <li
                      className="cursor-pointer hover:text-[#ffcc4d] hover:translate-x-1 duration-300 text-gray-200"
                      key={link.title}
                    >
                      {/* <span>{link.title}</span> */}
                      <Link to={`${link.link}`}>{link.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            
            

            {/* social links */}

            <div className="pl-4 md:pl-0 lg:pl-4 ">
            
              <div className="mt-8 space-y-3">
              <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify  ">
                Contact
                </h1>
                <div className="flex  gap-3">
                <div className=" pt-1">
                  <GiPositionMarker className="text-lg md:text-xl"/>
                </div>
                 <div>
                 <p>44/1,3rd street sangeeth nagar </p>
                 <p>Madurai-625018</p>
                 </div>
                </div>
                <div className="flex  gap-3 ">
                <div className=" pt-1">
                  <BiSolidPhoneCall className="text-lg md:text-xl"/>
                  </div>
                  <div className="block">
                  <button><Link to={'tel:9944362287'} className="hover:text-[#ffcc4d]" >+91 9944362287</Link></button>
                  <button><Link to={'tel:8939362287'} className="hover:text-[#ffcc4d]">+91 8939362287</Link></button>
                  </div>
                </div>

                <div className="flex gap-3">
                  <div className=" pt-1">
                  <HiMail className="text-lg md:text-xl"/>
                  </div>
                 <div className="flex flex-col ">
                 <Link to={'mailto:admin@arnet.in'} className="hover:text-[#ffcc4d]">admin@arnet.in </Link>
                 <Link to={'mailto:support@arnet.in'} className="hover:text-[#ffcc4d]">support@arnet.in</Link>
                 <Link to={'mailto:sales@arnet.in'} className="hover:text-[#ffcc4d]">sales@arnet.in</Link>
                 
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
