import React from 'react'
import { BiSolidComponent } from 'react-icons/bi'
import { BsLayoutWtf } from 'react-icons/bs'
import { LuLayers, LuMonitorStop, LuMoveRight, LuPaintbrush } from 'react-icons/lu'
import { MdOutlineDesignServices } from 'react-icons/md'

const FeatureSection = () => {
  return (
    <>
        <section className="py-10 lg:py-20 px-3 md:px-10 lg:px-14">
        <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
            <div className="flex items-end justify-between mb-10">
                <div className="max-w-2xl mx-auto text-center">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-300 text-gray-950">Feature Features</span>
                    <h2 className="text-4xl font-medium capitalize text-gray-950 mt-4">Our Features</h2>
                </div>
            </div>

            <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 items-center rounded-md overflow-hidden border border-gray-200">
                <div className="group border-b border-gray-200 hover:bg-gradient-to-b from-[#191a58] h-full">
                    <div className="sm:p-10 p-8">
                        <span className="">
                            <BiSolidComponent className="h-14 w-14 text-gray-950 " />
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Developer</h2>
                        <p className="text-base text-gray-800 mb-6">Our development team specializes in creating robust, scalable, and user-friendly web and mobile applications. We tailor each solution to meet your specific business objectives, ensuring seamless functionality and a superior user experience.</p>
                        
                    </div>
                </div>

                <div className="group border-b md:border-s border-gray-200 hover:bg-gradient-to-b from-[#191a58] h-full">
                    <div className="sm:p-10 p-8">
                        <span className="">
                            <LuMonitorStop className="h-14 w-14 text-gray-950" />
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Web Design</h2>
                        <p className="text-base text-gray-800 mb-6"> Crafting visually stunning and highly functional websites tailored to your brand's needs. Our web design services ensure a seamless user experience and a strong online presence.</p>
                        
                    </div>
                </div>

                <div className="group border-b xl:border-s border-gray-200 hover:bg-gradient-to-b from-[#191a58] h-full">
                    <div className="sm:p-10 p-8">
                        <span className="">
                            <LuLayers className="h-14 w-14 text-gray-950" />
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Digital Product Design</h2>
                        <p className="text-base text-gray-800 mb-6">We offer comprehensive digital production design services that blend creativity with technology. From interactive designs to engaging multimedia content, we bring your ideas to life, enhancing your digital presence across all platforms.</p>
                        
                    </div>
                </div>

                <div className="group border-b border-s xl:border-0 border-gray-200 hover:bg-gradient-to-b from-[#191a58] h-full">
                    <div className="sm:p-10 p-8">
                        <span className="">
                            <BsLayoutWtf className="h-14 w-14 text-gray-950" />
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Branding & Design</h2>
                        <p className="text-base text-gray-800 mb-6">Our branding and design services are focused on building strong, consistent, and memorable brand identities. We craft visual and strategic elements that resonate with your audience, helping your brand stand out in a crowded market.</p>
                        
                    </div>
                </div>

                <div className="group border-b xl:border-s md:border-b-0 border-gray-200 hover:bg-gradient-to-b from-[#191a58] h-full">
                    <div className="sm:p-10 p-8">
                        <span className="">
                            <MdOutlineDesignServices className="h-14 w-14 text-gray-950" />
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Ui/Ux & Design</h2>
                        <p className="text-base text-gray-800 mb-6">Designing intuitive user interfaces and engaging user experiences that enhance usability and satisfaction. We focus on creating designs that are both aesthetically pleasing and highly functional.</p>
                        
                    </div>
                </div>

                <div className="group md:border-s border-gray-200 hover:bg-gradient-to-b from-[#191a58] h-full">
                    <div className="sm:p-10 p-8">
                        <span className="">
                            <LuPaintbrush className="h-14 w-14 text-gray-950" />
                        </span>
                        <h2 className="text-2xl text-gray-950 font-medium mb-4 mt-8">Graphic Design</h2>
                        <p className="text-base text-gray-800 mb-6">Delivering creative and impactful graphic design solutions that communicate your message effectively. From logos to marketing materials, we provide designs that capture attention and leave a lasting impression.</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default FeatureSection