import React from 'react'

const HomeContent = () => {
  return (
    <section className="py-10 px-5 md:px-10 lg:px-14 bg-neutral-50">
        <div className="container mx-auto">
            <div data-aos="zoom-in" data-aos-duration="1000" className="rounded-lg py-20 px-6 shadow-lg bg-no-repeat bg-cover bg-[url('assets/other/bg-lines-2.png')] ">
                <div className="max-w-2xl mx-auto text-center">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-400/50 text-gray-950">Welcome to ASPIRING RADIANCE!</span>
                    <h2 className="md:text-4xl text-3xl font-bold text-gray-950 mt-5 animate-[moveLeftBounce_3s_linear_infinite]">Interested in a career that can take you to new heights?</h2>
                    <p className="text-base font-medium text-gray-500 mt-6">At ASPIRING RADIANCE, we're not just a team - we're a family of creative thinkers, strategic innovators, and passionate storytellers. Our mission is to elevate brands to new heights with compelling narratives, dynamic designs, and impactful strategies. If you're driven by creativity, excellence, and a desire to make a difference, we want to hear from you!</p>
                    
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeContent