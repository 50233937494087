import React from 'react'
import { LuMoveRight } from 'react-icons/lu'
import img1 from '../../assets/landing/marketing/img-5.jpg';

const AboutSection = () => {
  return (
    <div>
        <section className="py-10 lg:py-14 bg-white dark:bg-default-50">
        <div data-aos="zoom-in-right" data-aos-duration="1000" className="grid lg:grid-cols-2 items-center gap-6">
            <div className="relative h-[650px]">
                
                 <img src={img1} className='h-full object-cover' alt="" />
                    <div className="absolute inset-0 w-full h-full bg-black/20"></div>
                
                
            </div>

            <div className="py-10">
                <div className="max-w-lg md:ms-20 px-10">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-200 text-default-950">About</span>
                    <h2 className="text-4xl font-medium text-default-950 mt-5">Our mission is to bridge the gap between business and the digital world.</h2>
                    <p className="text-base font-medium mt-4">Our mission is to bridge the gap between business and the digital world by providing cutting-edge solutions that seamlessly integrate technology with your business goals. We empower companies to thrive online, ensuring they stay ahead in a rapidly evolving digital landscape.</p>
                    
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default AboutSection