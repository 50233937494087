import React from 'react'
import img1 from '../../assets/landing/marketing-3/img-7.png';
import { LuMoveRight } from 'react-icons/lu';
import { Link } from 'react-router-dom';

const CTASection = () => {
  return (
    <section className="py-10 lg:py-20 px-5 md:px-10 lg:px-14">
        <div className="container mx-auto">
            <div className="p-6 rounded-xl bg-[#0e104b]">
                <div className="grid md:grid-cols-3 items-center gap-6">
                    <div className="">
                        <img src={img1} className="max-w-full" alt="" />
                    </div>
                    <div className="md:col-span-2">
                        <div className="flex flex-col">
                            <div className="">
                                <h3 className="text-3xl/snug font-medium text-neutral-100 mb-2">Transform your concepts into reality <br /> and Enhance your thoughts visually.</h3>
                                <p className="text-sm text-neutral-400 mb-6">Bring your ideas to life with captivating visuals that amplify and clarify your thoughts, turning abstract concepts into impactful, tangible realities.</p>
                            </div>
                            <div className="">
                                <Link to={`/Contactus`} className="inline-flex items-center justify-center gap-2 bg-[#191c6b] hover:bg-[#232794] text-white py-2 px-6 rounded-md transition-all duration-500">Get in Touch
                                    <LuMoveRight className="h-5 w-5" />
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
  )
}

export default CTASection