import React from 'react'
import Dummy from './Dummy'

const Trash = () => {
    return (
        <div className='overflow-hidden'>
            <div data-aos="fade-left">
                <Dummy />
            </div>
            <div data-aos="fade-right">
                <Dummy />
            </div>
            <div data-aos="fade-left">
                <Dummy />
            </div>
        </div>
    )
}

export default Trash