import React from 'react'

import img1 from '../../assets/landing/creative/img-2.jpg';
import img2 from '../../assets/landing/creative/img-7.jpg';
import { IoChevronUp } from 'react-icons/io5';

const CurrentPlan = () => {
    return (
        <section className="py-10 lg:py-20 px-5 md:px-10 lg:px-14">
            <div className="container mx-auto" >
                <div className='' >
                    <div data-aos="flip-up" data-aos-duration="1000" className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-300 text-gray-950">Current Plan</span>
                            <h2 className="text-3xl font-medium capitalize text-gray-950 my-4">Experience Architectural Design Created with Passion and Profound Impact!</h2>
                        </div>
                    </div>

                    <div className="relative" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" >
                        <div className="hidden lg:block">
                            <div className="before:w-24 before:h-24 before:absolute before:-top-10 before:-start-10 before:bg-[url('assets/other/dot.svg')]"></div>
                            <div className="after:w-24 after:h-24 after:absolute after:-bottom-10 after:-end-10 after:bg-[url('assets/other/dot.svg')]"></div>
                        </div>

                        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center">
                            <div>
                                <div className="z-10 relative">
                                    <img src={img1} className="lg:h-72 h-full shadow rounded-md" alt="" />
                                </div>
                                <div className="-mt-28 xl:block hidden">
                                    <img src={img2} className="h-72 shadow rounded-md ms-auto" alt="" />
                                </div>
                            </div>



                            <div className="divide-y divide-gray-200  bg-gray-50  rounded-lg overflow-hidden shadow-sm text-sm font-medium text-gray-800">

                                <details className="group px-5 py-4 " open>
                                    <summary className="inline-flex cursor-pointer items-center justify-between gap-x-3 w-full text-left text-gray-950 transition-[height] duration-300 ease-in-out">
                                        <h2 className="text-lg font-semibold flex">Creative Director</h2>

                                        <span className="relative size-5 shrink-0">
                                            <IoChevronUp className={`transform origin-center transition duration-300 ease-in-out  group-open:-rotate-180`} />
                                        </span>
                                    </summary>

                                    <ul className="mt-3 px-5 leading-relaxed list-disc ">
                                        <li>Lead and inspire a team of designers and creative.</li>
                                        <li>Develop and execute visionary concepts and strategies for client brands.</li>
                                        <li>Ensure the highest quality of creative output and innovation.</li>
                                    </ul>
                                </details>

                                <details className="group px-5 py-4 ">
                                    <summary className="inline-flex cursor-pointer items-center justify-between gap-x-3 w-full text-left text-gray-950 transition-[height] duration-300 ease-in-out">
                                        <h2 className="text-lg font-semibold flex">Brand Strategist</h2>

                                        <span className="relative size-5 shrink-0">
                                            <IoChevronUp className={`transform origin-center transition duration-300 ease-in-out  group-open:-rotate-180`} />
                                        </span>
                                    </summary>

                                    <ul className="mt-3 px-5 leading-relaxed   list-disc ">
                                        <li>Craft and implement strategic brand plans.</li>
                                        <li>Conduct market research and analyse industry trends.</li>
                                        <li>Collaborate with clients to define brand identity and positioning.</li>
                                    </ul>
                                </details>

                                <details className="group px-5 py-4 ">
                                    <summary className="inline-flex cursor-pointer items-center justify-between gap-x-3 w-full text-left text-gray-950 transition-[height] duration-300 ease-in-out">
                                        <h2 className="text-lg font-semibold flex">Graphic Designer</h2>

                                        <span className="relative size-5 shrink-0">
                                            <IoChevronUp className={`transform origin-center transition duration-300 ease-in-out  group-open:-rotate-180`} />
                                        </span>
                                    </summary>

                                    <ul className="mt-3 px-5 leading-relaxed   list-disc ">
                                        <li>Create visually compelling designs across various platforms.</li>
                                        <li>Work closely with the creative team to develop brand assets.</li>
                                        <li>Ensure the highest quality of creative output and innovation.</li>
                                    </ul>
                                </details>

                                <details className="group px-5 py-4 ">
                                    <summary className="inline-flex cursor-pointer items-center justify-between gap-x-3 w-full text-left text-gray-950 transition-[height] duration-300 ease-in-out">
                                        <h2 className="text-lg font-semibold flex">Content Writer</h2>

                                        <span className="relative size-5 shrink-0">
                                            <IoChevronUp className={`transform origin-center transition duration-300 ease-in-out  group-open:-rotate-180`} />
                                        </span>
                                    </summary>

                                    <ul className="mt-3 px-5 leading-relaxed   list-disc ">
                                        <li>Develop engaging and persuasive content for various media.</li>
                                        <li>Research and understand client industries to create impactful messaging.</li>
                                        <li>Edit and proofread content to ensure clarity and accuracy.</li>
                                    </ul>
                                </details>

                                <details className="group px-5 py-4 ">
                                    <summary className="inline-flex cursor-pointer items-center justify-between gap-x-3 w-full text-left text-gray-950 transition-[height] duration-300 ease-in-out">
                                        <h2 className="text-lg font-semibold flex">Digital Marketing Specialist</h2>

                                        <span className="relative size-5 shrink-0">
                                            <IoChevronUp className={`transform origin-center transition duration-300 ease-in-out  group-open:-rotate-180`} />
                                        </span>
                                    </summary>

                                    <ul className="mt-3 px-5 leading-relaxed   list-disc ">
                                        <li>Implement and manage digital marketing campaigns.</li>
                                        <li>Analyse performance metrics and optimize strategies for success.</li>
                                        <li>Stay updated with the latest digital trends and tools.</li>
                                    </ul>
                                </details>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CurrentPlan