import React from 'react'
import { FiAirplay } from 'react-icons/fi'
import { GrBug } from 'react-icons/gr'
import { SiEbox } from 'react-icons/si'
import { TbLayoutGridAdd } from 'react-icons/tb'

const WhyChoose = () => {
  return (
    <section className="lg:py-20 py-10 px-5 md:px-10 lg:px-14 bg-neutral-50">
        <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
            <div className="grid xl:grid-cols-5 gap-6 items-center">
                <div className="xl:col-span-3">
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                        <div className="xl:-mt-8">
                            <div className="p-6 group rounded-xl border border-gray-200 hover:bg-[#0e104b]/10">
                                <TbLayoutGridAdd className="h-10 w-10 text-gray-950" />
                                <h2 className="text-2xl font-medium text-gray-950 mt-5">Expertise</h2>
                                <p className="text-base font-medium mt-3">Our team brings a wealth of experience in branding, with a track record of successful projects across various industries.</p>
                                
                            </div>
                        </div>

                        <div>
                            <div className="p-6 group rounded-xl border border-gray-200 hover:bg-[#0e104b]/10">
                                <SiEbox className="h-10 w-10 text-gray-950" />
                                <h2 className="text-2xl font-medium text-gray-950 mt-5">Creative Excellence</h2>
                                <p className="text-base font-medium mt-3">We blend innovative design with strategic thinking to create brands that are not only aesthetically pleasing but also strategically sound.</p>
                                
                            </div>
                        </div>

                        <div className="xl:-mt-8">
                            <div className="p-6 group rounded-xl border border-gray-200 bg-[#0e104b]/10">
                                <FiAirplay className="h-10 w-10 text-gray-950" />
                                <h2 className="text-2xl font-medium text-gray-950 mt-5">Client-Centric Approach</h2>
                                <p className="text-base font-medium mt-3">We partner closely with you, listening to your needs and involving you in every step of the branding process.</p>
                                
                            </div>
                        </div>

                        <div>
                            <div className="p-6 group rounded-xl border border-gray-200 hover:bg-[#0e104b]/10">
                                <GrBug className="h-10 w-10 text-gray-950" />
                                <h2 className="text-2xl font-medium text-gray-950 mt-5">Results-Driven</h2>
                                <p className="text-base font-medium mt-3">We are focused on delivering results that enhance your brand's reputation, engage your audience, and drive growth.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div className="xl:col-span-2">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-200 text-gray-950">Why Choose Us?</span>
                    <h2 className="text-4xl/tight font-medium text-gray-950 mt-6">Delivering Top-Notch Development Solutions.</h2>
                    <p className="text-base font-medium mt-5">Leveraging the power of design to solve complex problems and foster business solutions.</p>
                    
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhyChoose