import React from 'react'
import img1 from '../../assets/landing/agency/img-3.jpg';
import img2 from '../../assets/landing/agency/img-4.jpg';
import img3 from '../../assets/landing/agency/img-5.jpg';
import { MdOutlineArrowOutward } from 'react-icons/md';
import { PiDiamondsFour } from 'react-icons/pi';
import { LuLayers } from 'react-icons/lu';

const AboutSection = () => {
    return (
        <>

            {/* <!-- Start about --> */}
            <section className="lg:py-20 py-10 px-10">
                <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto ">
                    <div className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-300 text-default-950">About</span>
                            <h2 className="text-4xl font-medium capitalize text-default-950 my-4">Our Team of Dedicated Digital Professionals.</h2>
                            {/* <!-- <p className="text-base font-medium text-default-800">There are many variations of passages of passages of lorem lpsum available but the majority have suffered alteration in some form by inject rated humour or randomised this like.</p> --> */}
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 grid-cols-1 gap-x-10 gap-y-10">
                        <div className="grid sm:grid-cols-2 gap-6 items-center">
                            <div className="space-y-6">
                                <div>
                                    <img src={img1} alt="" className="max-w-full h-full rounded-lg" />
                                </div>
                                <div>
                                    <img src={img2} alt="" className="max-w-full h-full rounded-lg" />
                                </div>
                            </div>
                            <div>
                                <img src={img3} alt="" className="max-w-full h-full rounded-lg" />
                            </div>
                        </div>

                        <div>
                            <h2 className="text-3xl font-medium text-default-950">The Best of Product Your Business</h2>
                            <div className="flex items-center justify-center gap-6 mt-10">
                                <div>
                                    <PiDiamondsFour className="h-14 w-14 text-gray-950" />
                                </div>
                                <div>
                                    <h2 className="text-xl font-medium text-default-950">High Standerd</h2>
                                    <p className="text-base font-medium text-default-800 mt-4">Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-6 my-10">
                                <div>
                                    <LuLayers className="h-14 w-14 text-gray-950" />
                                </div>
                                <div>
                                    <h2 className="text-xl font-medium text-default-950">Ease of Communication</h2>
                                    <p className="text-base font-medium text-default-800 mt-4">Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>

                            {/* <a href="#" className="inline-flex items-center justify-center gap-2 border border-gray-100/80 text-gray-950 py-2 px-6 rounded-md hover:bg-[#0e104b] hover:text-white transition-all duration-500">View All Our News
                                <MdOutlineArrowOutward class="h-6 w-6" />
                            </a> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection