import React from 'react'
import img1 from '../../assets/option/woman-1.webp';
import img2 from '../../assets/option/man-front.webp';
import img3 from '../../assets/option/digital.webp';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const HeroSection = () => {


    return (
        <>
            {/* <!-- Hero Section Start --> */}
            <section className="" >
                <div data-aos="zoom-in" data-aos-duration="1000" className="grid xl:grid-cols-5 grid-cols-1 items-center relative">
                    <div className="relative xl:col-span-2 p-6 xl:p-0 xl:-me-24 xl:mt-0 -mt-24 z-10 order-2 xl:order-none">
                        <div className="max-w-xl mx-auto xl:ms-auto xl:me-0 rounded-xl bg-[#dfdfe6] dark:bg-gray-50">
                            <div className="p-6">
                                {/* <!-- Swiper --> */}
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    autoplay={{
                                        delay: 3000, // Time delay between slides (in ms)
                                        disableOnInteraction: false, // Keeps autoplay active after user interaction
                                    }}
                                    //   pagination={{ clickable: true }}
                                    loop={true} // Makes the slider loop infinitely
                                    className="h-full"
                                >



                                    <SwiperSlide>
                                        <div className="flex-col flex items-start justify-end xl:h-full">
                                            <span className="text-base font-medium uppercase tracking-wider text-gray-800">Digital agency</span>
                                            <h2 className="md:text-4xl/snug text-3xl font-semibold text-gray-950 mt-6">Beyond a Design Agency</h2>
                                            <p className="md:w-3/4 text-base text-gray-800 font-medium my-5">Their ability to understand our vision and translate it into a comprehensive marketing strategy is truly exceptional.</p>
                                            {/* <a href="#" className="inline-flex items-center justify-center gap-2 border border-gray-100/80 text-gray-950 py-2 px-6 rounded-md hover:bg-[#0e104b] hover:text-white transition-all duration-500">Get Solution

                                                <MdOutlineArrowOutward className="h-6 w-6" />
                                            </a> */}
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="flex-col flex items-start justify-end xl:h-full">
                                            <span className="text-base font-medium uppercase tracking-wider text-gray-800">Digital Marketing</span>
                                            <h2 className="md:text-4xl/snug text-3xl font-semibold text-gray-950 mt-6">Beyond Conventional Marketing</h2>
                                            <p className="md:w-[80%] text-base text-gray-800 font-medium my-5">Their expertise in digital marketing and deep understanding of our goals allowed them to craft a strategy that significantly boosted our online presence and engagement.</p>
                                           
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="flex-col flex items-start justify-end xl:h-full">
                                            <span className="text-base font-medium uppercase tracking-wider text-gray-800">Brand Evolution</span>
                                            <h2 className="md:text-4xl/snug text-3xl font-semibold text-gray-950 mt-6">Crafting Brands Vision</h2>
                                            <p className="md:w-3/4 text-base text-gray-800 font-medium 
                                            my-5">Their ability to grasp our brand essence and transform it into stunning designs and strategic narratives is truly exceptional.</p>
                                            
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>



                    <div className="xl:col-span-3 order-1 xl:order-none">
                        <Swiper
                            modules={[Autoplay, Pagination]}
                            autoplay={{
                                delay: 3000, // Time delay between slides (in ms)
                                disableOnInteraction: false, // Keeps autoplay active after user interaction
                            }}
                            // pagination={{ clickable: true }}
                            loop={true} // Makes the slider loop infinitely
                            className="h-full "
                        >
                            <SwiperSlide>

                                <div className="swiper-slide relative ">
                                    <img src={img1} className="h-[300px] md:h-[600px] xl:[650px] w-full object-cover" />
                                    <div className="absolute inset-0 bg-black/25"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide relative ">
                                    <img src={img2} className="h-[300px] md:h-[600px] xl:[650px] w-full object-cover" />
                                    <div className="absolute inset-0 bg-black/25"></div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide relative ">
                                    <img src={img3} className="h-[300px] md:h-[600px] xl:[650px] w-full object-cover" />
                                    <div className="absolute inset-0 bg-black/25"></div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

               
            </section>
        </>
    )
}

export default HeroSection