import React from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

import { HiChevronDown } from 'react-icons/hi'
import { Link, useLocation } from 'react-router-dom'

const Drobdown = () => {

  const { pathname } = useLocation();

  const pathActive = ""

  return (
    <>

    <Popover className="group px-3 lg:px-0">
      <PopoverButton className="flex items-center gap-2 outline-none p-0.5 border-b-2 border-transparent hover:border-[#0e104b] ">
        Our Works
        <HiChevronDown className="size-5 group-data-[open]:rotate-180" />
        
      </PopoverButton>
      <PopoverPanel anchor="bottom" className="flex flex-col shadow-md bg-[#fafdff] rounded-md z-50 divide-y mt-4">
        <Link to={"/BusinessSolution"} className='hover:bg-[#0e104b] hover:text-white px-10 py-2.5'>Business Solutions</Link>
        <Link to={"/DigitalMarketing"} className='hover:bg-[#0e104b] hover:text-white px-10 py-2.5'>Digital Marketing</Link>
        <Link to={"/Webdevelopment"} className='hover:bg-[#0e104b] hover:text-white px-10 py-2.5'>Web Development</Link>
      </PopoverPanel>
    </Popover>

    </>
  )
}

export default Drobdown