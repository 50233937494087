import React from 'react'
import img1 from '../../assets/landing/creative/img-2.jpg';
import img2 from '../../assets/landing/creative/img-3.jpg';
import img3 from '../../assets/landing/creative/img-1.jpg';




import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const HeroSection = () => {
    return (
        <>
            <section >
                <div data-aos="zoom-in" data-aos-duration="1000" className="grid xl:grid-cols-5 grid-cols-1 gap-6 items-end">
                    <div className="xl:col-span-3">
                        {/* <!-- Swiper --> */}
                        <Swiper
                            modules={[Autoplay, Pagination]}
                            autoplay={{
                                delay: 3000, // Time delay between slides (in ms)
                                disableOnInteraction: false, // Keeps autoplay active after user interaction
                            }}
                            //   pagination={{ clickable: true }}
                            loop={true} // Makes the slider loop infinitely
                            className="h-full"
                        >

                            <SwiperSlide>
                                <div className="swiper-slide relative">
                                    <img src={img1} className="h-full w-full" />
                                    <div className="absolute inset-0 bg-black/40"></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide relative">
                                    <img src={img2} className="h-full w-full" />
                                    <div className="absolute inset-0 bg-black/40"></div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="swiper-slide relative">
                                    <img src={img3} className="h-full w-full" />
                                    <div className="absolute inset-0 bg-black/40"></div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>

                    <div className="relative xl:col-span-2 p-6 xl:p-0">
                        {/* <!-- Swiper --> */}
                        <Swiper
                            modules={[Autoplay, Pagination]}
                            autoplay={{
                                delay: 3000, // Time delay between slides (in ms)
                                disableOnInteraction: false, // Keeps autoplay active after user interaction
                            }}
                            //   pagination={{ clickable: true }}
                            loop={true} // Makes the slider loop infinitely
                            className="h-full"
                        >

                            <SwiperSlide>
                                <div className="flex-col flex items-start justify-end xl:h-full pb-28">

                                    <h2 className="text-4xl font-semibold text-default-950 mt-6 max-w-2xl">Crafting Vision into Reality: Design Agency at Your Service</h2>
                                    <p className="w-3/4 text-base text-default-800 font-medium mt-5">Their ability to understand our vision and translate it into a comprehensive marketing strategy is truly exceptional.</p>
                                </div>
                            </SwiperSlide>



                            <SwiperSlide>
                                <div className="flex-col flex items-start justify-end xl:h-full pb-28">

                                    <h2 className="text-4xl font-semibold text-default-950 mt-6 max-w-2xl">Innovative Design Solutions: Unleashing Creativity for You</h2>
                                    <p className="w-3/4 text-base text-default-800 font-medium mt-5">Their ability to understand our vision and translate it into a comprehensive marketing strategy is truly exceptional.</p>
                                </div>
                            </SwiperSlide>



                            <SwiperSlide>
                                <div className="flex-col flex items-start justify-end xl:h-full pb-28">

                                    <h2 className="text-4xl font-semibold text-default-950 mt-6 max-w-2xl">Design Excellence Redefined: Elevate Your Brand with Our Agency</h2>
                                    <p className="w-3/4 text-base text-default-800 font-medium mt-5">Their ability to understand our vision and translate it into a comprehensive marketing strategy is truly exceptional.</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>




                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroSection