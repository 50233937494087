import React, { useEffect } from 'react';
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { IoChevronUp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/whatsapp'
import 'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/linkedin'
import 'react-social-icons/x'

const BackToTop = () => {

    useEffect(() => {
        const mybutton = document.querySelector('[data-toggle="back-to-top"]');

        const handleScroll = () => {
            if (mybutton) {
                if (window.pageYOffset > 72) {
                    mybutton.classList.remove("translate-x-16");
                } else {
                    mybutton.classList.add("translate-x-16");
                }
            }
        };

        const handleClick = (e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: "smooth" });
        };

        window.addEventListener("scroll", handleScroll);
        if (mybutton) {
            mybutton.addEventListener("click", handleClick);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (mybutton) {
                mybutton.removeEventListener("click", handleClick);
            }
        };
    }, []);

    return (
        <div className="fixed bottom-5 end-5 flex flex-col gap-1 items-center z-[1000]">
            
            <SocialIcon network="whatsapp" url="https://wa.me/8939362287" target='_blank' style={{width:"40px", height:"40px"}} />
            <SocialIcon network="facebook" url="https://www.facebook.com/share/Ex9Kbb9CTQCfrSJ4/?mibextid=qi2Omg" target='_blank' style={{width:"40px", height:"40px"}}/>
            <SocialIcon network="instagram" url="https://www.instagram.com/arnetworksssolutions?igsh=NmY2NTJqYjJzcG92" target='_blank' style={{width:"40px", height:"40px"}}/>
            <SocialIcon network="linkedin" url="#" style={{width:"40px", height:"40px"}}/>
            <SocialIcon network="x" url="#" style={{width:"40px", height:"40px"}}/>

            {/* <button className='text-white rounded-lg bg-[#0e104b] z-20 h-8 w-8 flex justify-center items-center'>
            <FaWhatsapp className='h-5 w-5' />
            </button>

            <Link to={'https://www.facebook.com/share/Ex9Kbb9CTQCfrSJ4/?mibextid=qi2Omg'} target='_blank' className='text-white rounded-lg bg-[#0e104b] z-20 h-8 w-8 flex justify-center items-center'>
            <FaFacebook className='h-5 w-5'/>
            </Link>

            <Link to={'https://www.instagram.com/arnetworksssolutions?igsh=NmY2NTJqYjJzcG92'} target='_blank'  className='text-white rounded-lg bg-[#0e104b] z-20 h-8 w-8 flex justify-center items-center'>
            <FaInstagram />
            </Link>

            <button className='text-white rounded-lg bg-[#0e104b] z-20 h-8 w-8 flex justify-center items-center'>
            <FaXTwitter className='w-5 h-5' />
            </button> */}

            <button className="h-11 w-11 bg-[#0e104b]/20 backdrop-blur-3xl rounded-full text-[#0e104b] flex justify-center items-center transition-all duration-500 translate-x-16 shadow-[inset_0px_0px_12px_0px] shadow-[#0e104b]/40 z-10" data-toggle="back-to-top">
                <IoChevronUp className="h-5 w-5" />
            </button>
        </div>
    );
};

export default BackToTop;
