import React from 'react'
import img1 from '../../assets/option/woman-3.jpg';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const WhatWeDo = () => {
    return (
        <section className="lg:py-20 py-10 px-5 md:px-10 lg:px-14">
            <div data-aos="zoom-in-right" data-aos-duration="1000" className="container mx-auto">
                <div className="grid xl:grid-cols-2 grid-cols-1 gap-x-10 gap-y-6">
                    <div>
                        <div className="flex items-end justify-between mb-10">
                            <div className="max-w-lg lg:text-start mx-auto xl:ms-0 text-center">
                                <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-300 text-gray-950">What We Do</span>
                                <p className="text-lg font-medium text-gray-950 mt-5">We help you to go online and increase your conversion rate Better design for your digital marketing.</p>
                            </div>
                        </div>

                        <div>
                            <div className="max-w-md mx-auto xl:ms-0 border border-gray-200 rounded-md">
                                <div className="divide-y divide-gray-200">
                                    <div className="p-6 flex items-center justify-between">
                                        <h3 className="text-2xl font-medium text-gray-950">Brand Strategy</h3>

                                    </div>

                                    <div className="p-6 flex items-center justify-between">
                                        <h3 className="text-2xl font-medium text-gray-950">Brand Identity Design</h3>

                                    </div>

                                    <div className="p-6 flex items-center justify-between">
                                        <h3 className="text-2xl font-medium text-gray-950">Brand Messaging</h3>

                                    </div>
                                    <div className="p-6 flex items-center justify-between">
                                        <h3 className="text-2xl font-medium text-gray-950">Market Research</h3>

                                    </div>

                                    <div className="p-6 flex items-center justify-between">
                                        <h3 className="text-2xl font-medium text-gray-950">Brand Implementation</h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative flex flex-col gap-y-6">
                        <img src={img1} className="rounded-xl h-full w-full object-cover " alt="" />
                        <div className="md:absolute top-auto xl:-start-28 md:start-10 md:bottom-10 ">

                            <div className="max-w-sm p-6 pb-0 bg-[#dfdfe6] rounded-lg group">
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    autoplay={{
                                        delay: 4000, // Time delay between slides (in ms)
                                        disableOnInteraction: false, // Keeps autoplay active after user interaction
                                    }}
                                    pagination={{ clickable: true }}
                                    loop={true} // Makes the slider loop infinitely
                                    className="h-full "
                                    // onChange={(e) => console.log(e)}
                                >

                                    <SwiperSlide className='pb-10'>

                                        <h3 className="text-2xl font-medium text-gray-950 mb-3">Brand Strategy</h3>
                                        <p className="text-base font-normal mb-5 text-justify"> Develop comprehensive brand strategies that align with your business goals and market positioning. </p>


                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <h3 className="text-2xl font-medium text-gray-950 mb-3">Brand Identity Design</h3>
                                        <p className="text-base font-normal mb-5 text-justify"> Create visually compelling brand identities, including logos, color palettes, typography, and other elements that define your brand's look and feel. </p>
                                    </SwiperSlide>

                                    <SwiperSlide>

                                        <h3 className="text-2xl font-medium text-gray-950 mb-3">Brand Messaging</h3>
                                        <p className="text-base font-normal mb-5 text-justify"> Craft clear and persuasive messaging that communicates your brand's values and resonates with your target audience. </p>

                                    </SwiperSlide>

                                    
                                    <SwiperSlide>

                                        <h3 className="text-2xl font-medium text-gray-950 mb-3">Market Research</h3>
                                        <p className="text-base font-normal mb-5 text-justify"> Conduct in-depth research to understand your audience, competitors, and market trends, ensuring your brand stands out effectively. </p>

                                    </SwiperSlide>

                                    
                                    <SwiperSlide>

                                        <h3 className="text-2xl font-medium text-gray-950 mb-3">Brand Implementation</h3>
                                        <p className="text-base font-normal mb-5 text-justify"> Oversee the execution of brand strategies across various touch points, including digital platforms, print materials, and more. </p>

                                    </SwiperSlide>

                                </Swiper>
                            </div>





                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhatWeDo