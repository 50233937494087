import React from 'react'
import img1 from '../../assets/landing/agency/img-10.jpg';

const WhyChoose = () => {
  return (
    <section className="lg:py-20 py-10 px-5 md:px-10 lg:px-14">
        <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
            <div className="flex items-end justify-between mb-10">
                <div className="max-w-2xl mx-auto text-center">
                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-300 text-default-950">Why Join Us?</span>
                    <p className="text-lg text-default-800 font-medium mt-5">We help you to increase your conversion rate Better design for your digital products.</p>
                </div>
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center">
                <div className="relative overflow-hidden -z-10">
                    <img src={img1} className="rounded-md h-full w-full" alt="" />
                    <div className="absolute inset-0 rounded-md bg-black/40"></div>
                </div>

                <div className="lg:-ms-20">
                    <div className="divide-y divide-gray-200 bg-gray-50 rounded-md shadow">
                        <div className="p-6 flex flex-wrap sm:flex-nowrap hover:shadow-md group items-center gap-6">
                            <div>
                                <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 text-xl border border-gray-200 text-gray-950 hover:text-primary bg-white/5 group-hover:bg-white">01</div>
                            </div>
                            <div>
                                <h3 className="text-2xl font-medium text-gray-950">Innovative Environment</h3>
                                <p className="text-base font-medium text-gray-800 mt-3">  Work with some of the brightest minds in the industry and stay ahead of the curve with cutting-edge strategies and technologies.</p>
                            </div>
                        </div>

                        <div className="p-6 flex flex-wrap sm:flex-nowrap hover:shadow-md group items-center gap-6">
                            <div>
                                <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 text-xl border border-gray-200 text-gray-950 hover:text-primary bg-white/5 group-hover:bg-white">02</div>
                            </div>
                            <div>
                                <h3 className="text-2xl font-medium text-gray-950">Collaborative Culture</h3>
                                <p className="text-base font-medium text-gray-800 mt-3">We believe in the power of teamwork and open communication. Share your ideas and watch them come to life in a supportive and dynamic setting.</p>
                            </div>
                        </div>

                        <div className="p-6 flex flex-wrap sm:flex-nowrap hover:shadow-md group items-center gap-6">
                            <div>
                                <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 text-xl border border-gray-200 text-gray-950 hover:text-primary bg-white/5 group-hover:bg-white">03</div>
                            </div>
                            <div>
                                <h3 className="text-2xl font-medium text-gray-950">Growth Opportunities</h3>
                                <p className="text-base font-medium text-gray-800 mt-3"> Your professional development is important to us. We offer continuous learning opportunities and career advancement paths.</p>
                            </div>
                        </div>

                        <div className="p-6 flex flex-wrap sm:flex-nowrap hover:shadow-md group items-center gap-6">
                            <div>
                                <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 text-xl border border-gray-200 text-gray-950 hover:text-primary bg-white/5 group-hover:bg-white">04</div>
                            </div>
                            <div>
                                <h3 className="text-2xl font-medium text-gray-950">Diverse Projects</h3>
                                <p className="text-base font-medium text-gray-800 mt-3"> From high-profile campaigns to fresh start-ups, work on a variety of exciting projects that challenge and inspire you.</p>
                            </div>
                        </div>

                        <div className="p-6 flex flex-wrap sm:flex-nowrap hover:shadow-md group items-center gap-6">
                            <div>
                                <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 text-xl border border-gray-200 text-gray-950 hover:text-primary bg-white/5 group-hover:bg-white">05</div>
                            </div>
                            <div>
                                <h3 className="text-2xl font-medium text-gray-950">Work-Life Balance</h3>
                                <p className="text-base font-medium text-gray-800 mt-3">  We value your well-being and offer flexible schedules and remote work options to help you maintain a healthy work-life balance.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WhyChoose