import React from 'react'
import img1 from '../../assets/option/schedule.webp';
import { BsDot } from 'react-icons/bs';

const OurMission = () => {
  return (
    <section className="py-10 lg:py-14 px-5 md:px-10 lg:px-14">
        <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
            <div className="grid lg:grid-cols-2 gap-6 items-center">
                <div>
                    <img src={img1}  alt=""  className="rounded-xl"/>
                </div>

                <div className="p-6">
                <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-200 text-default-950">Our Mission</span>
                    <h2 className="text-4xl font-medium text-gray-950 mt-4 ">Our Mission is to Empower Brands to Thrive in The Digital Age.</h2>
                    <p className="text-base font-normal text-gray-800 mt-5">Our mission is to craft distinctive brand experiences that connect deeply with your audience. We believe that a strong brand identity is the cornerstone of business success, and we are committed to building brands that are memorable, impactful, and enduring.</p>

                    <div className="grid grid-cols-1 my-5">
                        <div className="flex items-center gap-2">
                            <div>
                                <BsDot className="h-9 w-9 text-[#0e104b]" />
                            </div>
                            <h3 className="text-xl font-medium text-gray-950">Innovation & Support</h3>
                        </div>

                        <div className="flex items-center gap-2">
                            <div>
                                <BsDot className="h-9 w-9 text-[#0e104b]" />
                            </div>
                            <h3 className="text-xl font-medium text-gray-950">Data-Driven Decision</h3>
                        </div>

                        

                        <div className="flex items-center gap-2">
                            <div>
                                <BsDot className="h-9 w-9 text-[#0e104b]" />
                            </div>
                            <h3 className="text-xl font-medium text-gray-950">Business Consultancy</h3>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>
    </section>
  )
}

export default OurMission