import React from 'react'

const HomeSec = () => {
  return (
    <section className="relative overflow-hidden">
        <div className="px-5 md:px-10 lg:px-14 py-10 ">
        <div data-aos="zoom-in" data-aos-duration="1000" className="rounded-2xl overflow-hidden bg-no-repeat bg-cover bg-center bg-[url('assets/landing/marketing-3/img-1.jpg')]">


                <div className="bg-black/70 rounded-2xl">
                    <div className="container mx-auto">
                        <div className="p-6 relative">
                            <div className="flex h-full items-center justify-center md:py-16 lg:py-36">
                                <div className="text-center max-w-3xl mx-auto relative">
                                    <span className="py-1 px-3 rounded-md text-sm font-medium uppercase tracking-wider text-white bg-white/10">About Us</span>
                                    <h1 className="md:text-5xl/snug text-3xl font-medium text-white mt-10">Welcome to ASPIRING RADIANCE!</h1>
                                    <p className="w-3/4 mx-auto text-base font-normal text-white/80 mt-5">At ASPIRING RADIANCE, we transform brands into iconic names. Since 2018, we've been dedicated to creating compelling brand identities that resonate, inspire, and drive growth. Our passion lies in helping businesses define who they are and stand out in a crowded marketplace.</p>

                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeSec