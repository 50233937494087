import React from 'react'
import img1 from '../../assets/avatars/img-3.jpg';
import img2 from '../../assets/avatars/img-2.jpg';
import img3 from '../../assets/landing/marketing/img-11.png';
import { MdOutlineStarBorder } from 'react-icons/md';


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const Testimonial = () => {
    return (
        <>
            <section className="py-10 lg:py-14 px-3 md:px-10 lg:px-20">
                <div data-aos="zoom-in-left" data-aos-duration="1000" className="container mx-auto">
                    <div className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-200 text-default-950">Testimonials</span>
                            <h2 className="text-3xl font-medium capitalize text-default-950 my-4">What People Says?</h2>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center">
                        <div>


                            <Swiper
                                modules={[Autoplay, Pagination]}
                                autoplay={{
                                    delay: 3000, // Time delay between slides (in ms)
                                    disableOnInteraction: false, // Keeps autoplay active after user interaction
                                }}
                                //   pagination={{ clickable: true }}
                                loop={true} // Makes the slider loop infinitely
                                className="h-full"
                            >

                                <SwiperSlide>
                                    <div className="p-6 rounded-md bg-gray-100 dark:bg-gray-50">
                                        <div className="flex items-center gap-5">
                                            <img src={img1} className="h-14 w-14 rounded-full border-4 border-white/20" alt="" />
                                            <h3 className="text-lg font-medium text-default-950">Leonard Heister</h3>
                                        </div>
                                        <p className="text-base font-medium mt-4">There are many variations of passages of Lorem Ipsum available but the maj have suffered alteration in some form, by injected humour, or randomis words which don't look even slightly believable. If you are going</p>
                                        <div className="flex items-center gap-2 mt-4 text-yellow-300">
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />

                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className="p-6 rounded-md bg-gray-100 dark:bg-gray-50">
                                        <div className="flex items-center gap-5">
                                            <img src={img2} className="h-14 w-14 rounded-full border-4 border-white/20" alt="" />
                                            <h3 className="text-lg font-medium text-default-950">Jsercy Lee</h3>
                                        </div>
                                        <p className="text-base font-medium mt-4">Sed ut perspiciatis unde omnis iste nate error sitsau an voluptatem accusantium dolore mque laudantium, totamrem aperiam, inventore the ver quasi architecto again is there anyone who loves or pursues Variouses versions have evolved over the years.</p>
                                        <div className="flex items-center gap-2 mt-4 text-yellow-300">
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />
                                            <MdOutlineStarBorder className="h-5 w-5" />

                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                        </div>




                        <div className="lg:mx-auto me-0 ">
                            <div className="relative z-10">
                                <div className="absolute inset-y-0 -start-20 h-full w-full border-2 border-[#6d70da8f]/30 rounded-full -z-10 hidden lg:block bg-[#6d70da8f]/30"></div>
                                <img src={img3} className="rounded-full mx-auto" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial