import React from 'react'
import img1 from '../../assets/landing/marketing/img-1.jpg';
import img2 from '../../assets/landing/marketing/img-2.jpg';
import img3 from '../../assets/landing/marketing/img-3.jpg';
import { MdOutlineArrowOutward } from 'react-icons/md';

const HeroSection = () => {
    return (
        <>
            <section className="py-10 px-5 md:px-10 lg:px-14 relative overflow-hidden flex items-center justify-center">
                <div className="container mx-auto">
                    <div className="relative">
                        <div data-aos="zoom-in" data-aos-duration="1000" className="grid lg:grid-cols-2 gap-6 items-center">
                            <div className="max-w-md mx-auto text-center lg:ms-0 lg:text-start">
                                <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-300 text-default-900">startups Marketing</span>
                                <h2 className="md:text-5xl/tight text-4xl font-medium text-default-900 my-5">Digital marketing the new way.</h2>
                                <p className="text-base font-medium">We are a creative studio specializing in UI/UX design, development and strategy. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                {/* <a href="#" className="inline-flex items-center justify-center gap-2 mt-3 md:mt-5 text-gray-300 py-2 px-6 rounded-md bg-[#0e104b] hover:text-white transition-all duration-500">Get Solution
                                           
                                                <MdOutlineArrowOutward className="h-6 w-6"/>
                                            </a> */}
                            </div>

                            <div>
                                <div className="flex gap-4 md:gap-6 tilt items-center">
                                    <div className="flex flex-col gap-4 md:gap-6  w-full">
                                        <img src={img1} className="h-full w-full rounded-lg object-cover" alt="" />
                                    </div>
                                    <div className="flex flex-col gap-4 md:gap-6 pb-10 md:pb-16 w-full relative">
                                        <img src={img2} className="w-full h-40 md:h-[278px] rounded-lg object-cover" alt="" />
                                        <img src={img3} className="w-full h-40 md:h-[278px] rounded-lg object-cover" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroSection