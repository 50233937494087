import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Aos from "aos";

import Contactus from "./components/Contactus";
import ScrollToTop from "./components/ScrollToTop";
import About from "./components/AboutPage";
import BusinessSolution from "./components/BusinessSolutionPage";
import DigitalMarketing from "./components/DigitalMarketingPage";
import Webdevelopment from "./components/Webdevelopment";
import Career from "./components/Career";
import HomePage2 from "./components/homePage";
import Trash from "./components/trash";

function App() {
	
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage2 />} />
        <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
        <Route path="/BusinessSolution" element={<BusinessSolution />} />
        <Route path="/Webdevelopment" element={<Webdevelopment />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/About" element={<About />} />
        <Route path="/Career" element={<Career />} />

        <Route path="/trash" element={<Trash />} />
      </Routes>
    </Router>
  );
}

export default App;
