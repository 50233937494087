import React from 'react'
import img1 from '../../assets/landing/marketing/img-9.jpg';
import img2 from '../../assets/landing/marketing/img-10.jpg';
import Accordion from '../Accordion';

const FoaSection = () => {
    return (
        <>
            <section className="py-10 lg:py-14 px-3 md:px-10 bg-white dark:bg-default-50">
                <div className="container mx-auto overflow-hidden">
                    <div data-aos="flip-up" data-aos-duration="1000" className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-200 text-default-950">Our FAQ</span>
                            <h2 className="text-3xl font-medium capitalize text-default-950 my-4">Witness the creative process that drives our digital marketing strategies.</h2>
                        </div>
                    </div>

                    <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center">
                        <div className="relative mb-20 lg:mb-0">
                            <div className="relative">
                                <img src={img1} className="mx-auto" alt="" />
                            </div>
                            <div className="absolute inset-x-0 -bottom-14 hidden sm:block">
                                <img src={img2} className="h-full" alt="" />
                            </div>
                        </div>

                        <div className="lg:pb-20 ">

                            <div className="p-4  space-y-5 rounded-lg">
                                <Accordion
                                    title="How can digital marketing benefit my business?"
                                    answer="It can increase brand visibility, drive website traffic, generate leads, and ultimately boost sales and revenue."
                                />
                                <Accordion
                                    title="Do I need to have a large budget for digital marketing?"
                                    answer="We work with businesses of various budgets and can create strategies that align with your financial resources while delivering meaningful results."
                                />
                                <Accordion
                                    title="Unique and Influential Design"
                                    answer="provides a comprehensive range of digital services, including website design and development, digital marketing, search engine optimization"
                                />

                                <Accordion
                                    title="The Power of Influencer Marketing"
                                    answer="Absolutely! We offer content creation services, including blog posts, social media content, and multimedia assets."
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FoaSection