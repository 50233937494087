import React from 'react'

import img1 from '../../assets/landing/creative/img-3.jpg';
import { LuMoveRight } from 'react-icons/lu';

const ServicesSection = () => {
    return (
        <>
            <section className="py-10 lg:py-20 px-3 md:px-10 lg:px-14">
                <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
                        <div className="2xl:py-20">
                            <div className="flex items-end justify-between mb-10">
                                <div className="max-w-md lg:text-start mx-auto xl:ms-0 text-center">
                                    <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-300 text-default-950">Services</span>
                                    <p className="text-base font-medium text-default-800 mt-5">We help you to go online and increase your conversion rate Better design for your digital products.</p>
                                </div>
                            </div>

                            <div className="bg-white rounded-md">
                                <div className="grid 2xl:grid-cols-2">
                                    <div className="divide-y divide-white/10">
                                        <div className="p-6 flex items-center justify-between">
                                            <h3 className="text-2xl font-medium text-default-950">Graphic Design</h3>
                                            <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 border border-default-200 text-default-950 hover:text-primary bg-white/5 hover:bg-white">
                                                <LuMoveRight className="w-6 h-6" />
                                            </div>
                                        </div>

                                        <div className="p-6 flex items-center justify-between">
                                            <h3 className="text-2xl font-medium text-default-950">Web Design</h3>
                                            <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 border border-default-200 text-default-950 hover:text-primary bg-white/5 hover:bg-white">
                                                <LuMoveRight className="w-6 h-6" />
                                            </div>
                                        </div>

                                        <div className="p-6 flex items-center justify-between">
                                            <h3 className="text-2xl font-medium text-default-950">Digital Marketing</h3>
                                            <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 border border-default-200 text-default-950 hover:text-primary bg-white/5 hover:bg-white">
                                                <LuMoveRight className="w-6 h-6" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="2xl:border-s 2xl:border-t-0 border-t border-default-200 divide-y divide-white/10">
                                        <div className="p-6 flex items-center justify-between">
                                            <h3 className="text-2xl font-medium text-default-950">Development</h3>
                                            <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 border border-default-200 text-default-950 hover:text-primary bg-white/5 hover:bg-white">
                                                <LuMoveRight className="w-6 h-6" />
                                            </div>
                                        </div>
                                        <div className="p-6 flex items-center justify-between">
                                            <h3 className="text-2xl font-medium text-default-950">UI/UX Design</h3>
                                            <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 border border-default-200 text-default-950 hover:text-primary bg-white/5 hover:bg-white">
                                                <LuMoveRight className="w-6 h-6" />
                                            </div>
                                        </div>
                                        <div className="p-6 flex items-center justify-between">
                                            <h3 className="text-2xl font-medium text-default-950">Branding</h3>
                                            <div className="h-12 w-12 rounded-md flex items-center justify-center transition-all duration-500 border border-default-200 text-default-950 hover:text-primary bg-white/5 hover:bg-white">
                                                <LuMoveRight className="w-6 h-6" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="relative">
                            <img src={img1} className="h-full w-full rounded-md" alt="" />
                            <div className="absolute inset-0 bg-default-50/60"></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServicesSection