import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import HeroSection from './HeroSection'
import Services from './Services'
import AboutSection from './AboutSection'
import FoaSection from './FoaSection'
import Testimonial from './Testimonial'
import BackToTop from '../BackToTop'

const BusinessSolution = () => {
    return (
        <section>
            <Navbar />

            <HeroSection />

            <Services />

            <AboutSection />

            <FoaSection />

            <Testimonial />

            <Footer />

            <BackToTop />
        </section>
    )
}

export default BusinessSolution