import React from 'react'
import { IoChevronUp } from 'react-icons/io5'
import img1 from '../../assets/landing/agency/img-11.jpg';
import img2 from '../../assets/landing/agency/img-12.jpg';
import Accordion from '../Accordion';

const Faq = () => {
    return (
        <>
            <section className="py-20 px-3 md:px-10">
                <div className="container mx-auto">
                    <div data-aos="flip-up" data-aos-duration="1000" className="flex items-end justify-between mb-10">
                        <div className="max-w-2xl mx-auto text-center">
                            <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-default-300 text-default-950">Our FAQ</span>
                            <h2 className="text-3xl font-medium capitalize text-default-950 my-4">Watch the creative process behind our digital marketing journey!</h2>
                        </div>
                    </div>

                    <div className="relative overflow-hidden">
                        <div className="hidden lg:block">
                            <div className="before:w-24 before:h-24 before:absolute before:-top-10 before:-start-10 before:-z-10 before:rotate-180  after:w-24 after:h-24 after:absolute after:-bottom-10 after:-end-10 after:-z-10 "></div>
                        </div>

                        <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" className="grid lg:grid-cols-2 grid-cols-1 gap-6 items-center" >
                            <div className="lg:pb-20 ">

                                <div className="p-4  space-y-5 rounded-lg">
                                    <Accordion
                                        title="What is web design, and why is it important?"
                                        answer="Web design is the process of creating the visual and functional elements of a website."
                                    />
                                    <Accordion
                                        title="Are these projects real or concepts?"
                                        answer="The projects featured in my portfolio are a mix of real-world projects I've completed and conceptual designs that showcase my creative thinking and design skills."
                                    />
                                    <Accordion
                                        title="How often is the portfolio updated?"
                                        answer="I strive to keep my portfolio up-to-date with my latest work and projects. I regularly update it to reflect my evolving skills and design philosophy."
                                    />

                                    <Accordion
                                        title="Can I leave feedback or comments on the portfolio projects?"
                                        answer="At the moment, I don't have a comments section on the portfolio pages. However, I welcome feedback and inquiries."
                                    />
                                </div>


                            </div>

                            <div>
                                <div className="-z-10 relative">
                                    <img src={img1} className="lg:h-72 h-full rounded-md" alt="" />
                                </div>
                                <div className="-mt-28 xl:block hidden text-end">
                                    <div className="inline-block bg-gray-100 dark:bg-gray-50 p-2 rounded-md ms-auto text-end">
                                        <img src={img2} className="ms-auto rounded" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </>
    )
}

export default Faq