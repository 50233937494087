import React from 'react'

import HomeSec from './HomeSec'
import WhatWeDo from './WhatWeDo'
import OurMission from './OurMission'
import WhoWeAre from './WhoWeAre'
import WhyChoose from './WhyChoose'
import OurValues from './OurValues'
import Navbar from '../Navbar'
import Footer from '../Footer'
import BackToTop from '../BackToTop'

const About = () => {
    return (
        <section>
            <Navbar />

            <HomeSec />

            <OurMission />

            <WhyChoose />

            <WhoWeAre />

            <WhatWeDo />

            <OurValues />

            <Footer />

            <BackToTop />
        </section>
    )
}

export default About