import React from 'react'
import { Link } from 'react-router-dom'

const HowToApply = () => {
  return (
    <section className="relative overflow-hidden">
        <div className="px-5 md:px-10 lg:px-14 py-10 ">
        <div data-aos="zoom-in" data-aos-duration="1000" className="rounded-2xl overflow-hidden bg-no-repeat bg-cover bg-center bg-[url('assets/option/bg-img.png')]">


                <div className="bg-black/80 rounded-2xl">
                    <div className="container mx-auto">
                        <div className="p-6 relative">
                            <div className="flex h-full items-center justify-center md:py-16 lg:py-26">
                                <div className="text-center max-w-3xl mx-auto relative">
                                    <span className="py-1 px-3 rounded-md text-sm font-medium uppercase tracking-wider text-white bg-white/10">How to Apply</span>
                                    <h1 className="md:text-5xl/snug text-3xl font-medium text-white mt-10">Join Us and Make an Impact <span className='text-[#ffcc4d]'>!</span></h1>
                                    <p className="w-3/4 mx-auto text-base font-normal text-white/80 mt-5">Ready to take the next step in your career? Send your resume, cover letter, and portfolio (if applicable) to <Link to={'mailto:admin@arnet.in'} className="hover:text-[#ffcc4d] underline text-sky-600">admin@arnet.in </Link> . We look forward to discovering how your unique skills and experiences can contribute to our team.</p>

                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HowToApply