import React from 'react'
import { LuMoveRight } from 'react-icons/lu'
import img1 from '../../assets/landing/company/img-8.jpg';

const AboutSection = () => {
  return (
    <>
        <section className="py-10 px-5 md:px-10 lg:px-14 overflow-hidden">
        <div data-aos="zoom-in-up" data-aos-duration="1000" className="container mx-auto">
            <div className="bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_24px] rounded-3xl overflow-hidden relative ">
                <div className="grid lg:grid-cols-2 gap-6 items-center">
                    <div className="text-center relative p-6">
                        <span className="py-1 px-3 rounded-md text-xs font-medium uppercase tracking-wider border border-gray-200 text-gray-950">Highlighted Projects</span>
                        <h2 className="text-3xl font-medium text-gray-950 mt-4">Innovative Design Solutions <span className='transition-transform duration-700 animate-pulse'>💡</span></h2>
                        <p className="text-base font-medium mt-5 mb-8">Customize your design using a variety of theme options in the WordPress Customizer and witness instant changes.</p>
                       
                        
                    </div>
                    <img src={img1} className="h-full w-full" alt="" />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutSection