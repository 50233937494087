import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import WhyChoose from './WhyChoose'
import HomeContent from './HomeContent'
import BackToTop from '../BackToTop'
import CurrentPlan from './CurrentPlan'
import HowToApply from './HowToApply'

const Career = () => {
  return (
    <section>
      <Navbar />

      <HomeContent />

      <WhyChoose />

      <CurrentPlan />

      <HowToApply />

      <Footer />

      <BackToTop />
    </section>
  )
}

export default Career