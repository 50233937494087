import React, { useState } from "react";
import { IoChevronUp } from "react-icons/io5";

const Accordion = ({ title, answer }) => {
	const [accordionOpen, setAccordionOpen] = useState(false);

	return (

		<div className="border border-gray-200 bg-gray-50  rounded-lg overflow-hidden">
			<button
				onClick={() => setAccordionOpen(!accordionOpen)}
				className="capitalize px-6 py-4 inline-flex items-center justify-between gap-x-3 w-full text-left text-gray-950 transition-[height] duration-300 ease-in-out"
			>
				<span className="text-base font-semibold flex">{title}</span>

				
				<IoChevronUp className={`transform origin-center transition duration-300 ease-in-out ${accordionOpen ? "-rotate-180" : "rotate-180"}`}/>

				
			</button>
			<div
				className={`grid w-full overflow-hidden transition-[height] duration-300 ease-in-out text-slate-600 text-sm ${accordionOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"}`}
			>
				<div className={`overflow-hidden text-gray-600 text-sm font-medium px-6 pt-0 ${accordionOpen ? "pb-4" : "pb-0"}`}>{answer}

				</div>
			</div>


			
		</div>
	);
};

export default Accordion;
