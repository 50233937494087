import React, { useState, useEffect } from 'react';
import img1 from '../assets/logo-horizontal-full-grap.png';
import img2 from '../assets/logo-icon.png';
import img3 from '../assets/logo-medium.png';
import Drobdown from './Drobdown';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { IoCallSharp } from 'react-icons/io5';
import { HiMail } from 'react-icons/hi';


const Navbar = () => {
    // navbar scroll bg color change
    const [isSticky, setIsSticky] = useState(false);


    const handleScroll = () => {
        const scrollTop = window.scrollY;
        setIsSticky(scrollTop > 60);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // mobile menu toggle 
    const [nav, setNav] = useState(false)

    const handleNav = () => {
        setNav(!nav)
    }

    // select state
    const { pathname } = useLocation();

    return (
        <>
            <nav className='hidden md:flex gap-3 py-1 px-10 text-sm font-medium text-white bg-gradient-to-r from-[#121346] '>
                <button className='inline-flex items-center gap-1'>
                    <IoCallSharp />
                    <Link to={'tel:9944362287'} className='hover:text-[#ffcc4d]'>9944362287 </Link>
                </button>

                <button className='inline-flex items-center gap-1'>
                    <HiMail />
                    <Link to={'mailto:admin@arnet.in'} className='hover:text-[#ffcc4d]'>admin@arnet.in </Link>
                </button>
            </nav>
            <header className={`sticky top-0 z-50 left-0 w-full transition-colors duration-300 ${isSticky ? 'bg-[#fafdff] shadow-md' : 'bg-white'}`}>
                <div className=' px-3 md:px-5 py-2 flex justify-between w-full items-center '>
                    <Link to={'/'}>
                        <img src={img1} className='hidden lg:block h-14 object-cover ' alt="logo" />
                        <img src={img2} className=' md:hidden object-cover h-10' alt="logo" />
                        <img src={img3} className='hidden md:block lg:hidden object-cover h-10  -translate-x-4' alt="logo" />
                    </Link>

                    {/* mobile menu */}

                    <div onClick={handleNav} className='lg:hidden'>
                        {nav ? <AiOutlineClose /> : <AiOutlineMenu />}
                    </div>

                    <div className={nav ? 'fixed  h-full w-2/3 md:w-[25%] shadow bg-white left-0 top-0 transform duration-300' : 'fixed left-[-100%] z-50 divide-y'}>
                        <div className=' flex px-3  py-2'>
                            <Link to={'/'} className='w-full  '>

                                <img src={img2} className=' lg:hidden flex justify-center  object-cover h-10' alt="logo" />
                                {/* <img src={img3} className=' lg:hidden flex justify-center  object-cover h-10' alt="logo" /> */}
                            </Link>
                        </div>

                        <ul className='capitalize flex w-full flex-col h-full gap-4 font-medium '>
                            <li className='w-full hover:bg-[#191c6b] hover:text-white text-gray-700 px-3 py-1.5'><Link to={'/'} >home</Link></li>

                            <li className='w-full hover:bg-[#191c6b] hover:text-white text-gray-700 px-3 py-1.5'><Link to={'/About'} className=''>about us</Link></li>


                            <li className='w-full hover:bg-[#191c6b] hover:text-white text-gray-700 px-3 py-1.5'><Link to={'/Career'} className=''>careers</Link></li>

                            <Drobdown />

                        </ul>
                    </div>

                    {/* Desktop menu */}

                    <nav className='hidden lg:block'>
                        <ul className='capitalize font-medium flex gap-8 '>
                            <li><Link to={'/'} className={`${pathname == "/" ? "text-[#0e104b] border-b-2 border-[#0e104b]" : ""}  hover:text-[#0e104b] hover:border-b-2 hover:border-[#0e104b] transition-transform duration-200 py-1`}>home</Link></li>

                            <li><Link to={'/About'} className={`${pathname == "/About" ? "text-[#0e104b] border-b-2 border-[#0e104b]" : ""}  hover:text-[#0e104b] hover:border-b-2 hover:border-[#0e104b] transition-transform duration-200 py-1`}>about us</Link></li>

                            <Drobdown />

                            <li><Link to={'/Career'} className={`${pathname == "/Career" ? "text-[#0e104b] border-b-2 border-[#0e104b]" : ""}  hover:text-[#0e104b] hover:border-b-2 hover:border-[#0e104b] transition-transform duration-200 py-1`}>careers</Link></li>

                        </ul>
                    </nav>

                    <Link to={'/Contactus'} className='hidden lg:block'>
                        <button className=' hover:bg-[#0e104b] text-[#0e104b] border-2  border-[#191c6b] hover:text-[#ffffff] px-5 py-2 rounded-md font-semibold'>Let's Talk</button></Link>
                </div>
            </header>


        </>
    );
};

export default Navbar;


